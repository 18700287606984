<template>
  <div>
    <div v-if="this.$route.name === 'Expenses'">
      <Expenses />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import Expenses from "@/views/companyApp/expenses/Expenses.vue";

export default {
  name: 'ExpensesParent',
  components: {
    Expenses
  }
}
</script>
