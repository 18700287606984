<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto mb-4" flat>
          <v-card-title class="pa-0 primary--text">
            <v-icon color="primary">mdi-file-delimited-outline</v-icon>
            <h6 class="ms-2 font-weight-regular">
              {{ $t("expenses.relativeNumber") }}
            </h6>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0 px-2 mt-2" v-if="complusoryNumbers">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col>
                  <v-text-field
                    class="asterisk"
                    hide-details="auto"
                    v-model="model.expenses"
                    :label="$t('expenses.expenses')"
                    :rules="expensesRequired"
                    required
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    class="asterisk"
                    hide-details="auto"
                    v-model="model.purchases_vat"
                    :label="$t('expenses.purchasesVat')"
                    :rules="purchasesVatRequired"
                    required
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    class="asterisk"
                    hide-details="auto"
                    v-model="model.supplier_withholding_tax"
                    :label="$t('expenses.supplierWithHoldingTax')"
                    :rules="supplierHoldingTaxRequired"
                    required
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    class="asterisk"
                    hide-details="auto"
                    v-model="model.equipment_purchase_vat"
                    :label="$t('expenses.equipmentPurchaseVat')"
                    :rules="equipmentPurchaseVatRequired"
                    required
                  />
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    v-if="accessRight.includes('create') || accessRight.includes('edit')"
                    class="mt-4 justify-end"
                    color="primary"
                    small
                    :loading="loading"
                    :disabled="!valid"
                    @click="saveComplusoryNumbers"
                  >
                    {{ $t("expenses.save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider />
    <v-row no-gutters class="mt-4">
      <v-col cols="12" class="mb-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("expenses.expensesList") }}</span
        >      
        <CommonAdd v-if="accessRight.includes('create')" :name="$t('expenses.expenses')" pathName="expenses/addExpenses" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else-if="expensesList.data">
            <v-data-table
              dense
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="expensesList.data"
              :page.sync="page"
              :items-per-page="limit"
              :options.sync="pagination"
              :server-items-length="expensesList.meta.total"
              style="word-break: break-word"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
              }"
              @dblclick:row="redirectOnEditPage"
              item-class="py-4"
              :search="search"
              :sort-by.sync="sortByValue"
              :sort-desc.sync="sortDescValue"
              @update:sort-by="(val) => buildUrl(val, 'sortKey')"
              @update:sort-desc="(val) => buildUrl(val, 'sortDec')"
            >
              <template v-slot:top>
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="10">
                    </v-col>
                    <v-col cols="2" class="text-end d-flex">
                      <v-text-field
                        :full-width="false"
                        dense
                        hide-details="auto"
                        v-model="search"
                        @input="buildUrl"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </div>
              </template>
              <template v-slot:[`item.gross_profit`]="props">
                <v-checkbox
                  dense
                  readonly
                  color="primary"
                  style="height: inherit"
                  :input-value="
                    props.item.gross_profit ? true : false
                  "
                />
              </template>

              <template v-slot:[`item.actions`]="props">
                <v-menu
                  v-if="
                    accessRight.includes('edit') ||
                    accessRight.includes('delete')
                  "
                  offset-x
                  left
                  content-class="elevation-2"
                  flat
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="accessRight.includes('edit')"
                      dense
                      class="primary--text"
                      :to="{
                        path: 'expenses/expensesAction/' + props.item.id,
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("common.edit")
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="primary--text"
                          >mdi-circle-edit-outline</v-icon
                        >
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item
                      v-if="accessRight.includes('delete')"
                      dense
                      @click="
                        (confirmationDialog = true), (delete_item = props.item)
                      "
                    >
                      <v-list-item-content class="error--text">
                        <v-list-item-title>{{
                          $t("common.delete")
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="error--text"
                          >mdi-delete-sweep-outline</v-icon
                        >
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2"
                      >{{ $t("common.numberOfTotalRows") }}
                      {{ expensesList.meta.total }}</span
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        @input="buildUrl"
                        :length="expensesList.meta.last_page"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else>
            {{ $t("common.noDataFound") }}</v-card-text
          >
        </v-card>
      </v-col>
      <confirmation-dialog
        :dialog.sync="confirmationDialog"
        :delete_item.sync="delete_item"
        @deleteItem="deleteItem"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CommonAdd from '@/components/CommonAdd';

export default {
  name: "Expenses",
  components: {
    ListSkeleton,
    ConfirmationDialog,
    CommonAdd
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      listSkeleton: false,
      delete_item: null,
      loading: false,
      valid: false,
      confirmationDialog: false,
      pagination: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: 10,
        sortBy: [this.$route.query.order_by || "description"],
        sortDesc: this.$route.query.order_by
          ? this.$route.query.sortDec
            ? [true]
            : [false]
          : this.$route.query.sortDec
          ? [true]
          : [false],
      },
      model: {},
      search: this.$route.query.search || "",
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("expenses.id"), value: "id", align: "start" },
        { text: this.$t("expenses.description"), value: "description", width: "450px" },
        { text: this.$t("expenses.accountNumber"), value: "bookkeeping_number", width: "150px" },
        {
          text: this.$t("expenses.balanceSheetSection"),
          value: "balanceSheetSection.description",
          sortable: false,
          width: "230px",
        },
        {
          text: this.$t("expenses.grossProfit"),
          value: "gross_profit",
          sortable: false,
        },
        {
          text: this.$t("expenses.actions"),
          sortable: false,
          value: "actions",
        },
      ];
    },
    params() {
      return {
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc,
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
      };
    },
    ...mapGetters({
      expensesList: "expenses/expensesList",
      accessRight: "accessRight",
      complusoryNumbers: "expenses/complusoryNumbers"
    }),
    expensesRequired() {
      return [
        (v) => !!v || this.$t("formRules.expensesRequired"),
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("expenses.expenses"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
    equipmentPurchaseVatRequired() {
      return [
        (v) => !!v || this.$t("formRules.equipmentPurchaseVatRequired"),
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("expenses.equipmentPurchaseVat"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
    purchasesVatRequired() {
      return [
        (v) => !!v || this.$t("formRules.purchasesVatRequired"),
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("expenses.purchasesVat"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
    supplierHoldingTaxRequired() {
      return [
        (v) => !!v || this.$t("formRules.supplierHoldingTaxRequired"),
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("expenses.supplierWithHoldingTax"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
  },
  watch: {
    params: {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.paginate(false);
      },
      deep: true,
    },
    complusoryNumbers: {
      handler: function (val) {
        if (val.length > 0) {
          this.model = Object.assign({}, this.model, {
            expenses: val[0].expenses,
            purchases_vat: val[0].purchases_vat,
            supplier_withholding_tax: val[0].supplier_withholding_tax,
            equipment_purchase_vat: val[0].equipment_purchase_vat,
          });
        }
      },
      deep: true
    },
    "$route.query": {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.page = Number(this.$route.query.page) || 1;

        this.sortByValue = this.$route.query.order_by || null;

        this.sortDescValue = this.$route.query.sortDec ? true : null;

        this.search = this.$route.query.search || "";
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.listSkeleton = true;
    this.$store.dispatch('expenses/GetComplusoryNumbers')
    this.$store.commit("expenses/SET_EXPENSES_QUERY", this.$route.query);
    if (Object.keys(this.$route.query).length) {
      this.paginate(false);
    } else {
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
      this.pagination.sortBy = ["description"];
      this.pagination.sortDesc = [true];
    }
  },
  methods: {
    async saveComplusoryNumbers() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store.dispatch('expenses/SetComplusoryNumbers', this.model)
        this.loading = false;
      }
    },
    async paginate(isSearch = false) {
      const {
        sortBy = this.pagination.sortBy,
        sortDesc = this.pagination.sortDesc,
        page = this.pagination.page,
        itemsPerPage = this.pagination.itemsPerPage,
      } = this.params;
      let concateWhereAnd = "";
      let whereAndVal = "|";
      await this.$store
        .dispatch("expenses/GetExpenses", {
          page: isSearch ? 1 : page,
          limit: itemsPerPage,
          where: "account_type|1",
          where_and:
            concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1)
              ? concateWhereAnd.slice(0, -1) + whereAndVal.slice(0, -1)
              : undefined,
          where_like: this.search
            ? sortBy[0]
              ? sortBy[0] + "|" + this.search
              : sortBy[1]
              ? sortBy[1] + "|" + this.search
              : sortBy[2]
              ? sortBy[2] + "|" + this.search
              : sortBy[3]
              ? sortBy[3] + "|" + this.search
              : "id,description,bookkeeping_number|" + this.search
            : "id,description,bookkeeping_number|",
          order_by: sortBy?.[0]
            ? sortBy?.[0] + ",id|" + (sortDesc?.[0] ? "desc" : "asc") + ",desc"
            : "description, id, bookkeeping_number|" +
              (sortDesc?.[0] ? "asc" : "desc") +
              ",desc",
        })
        .then(() => (this.listSkeleton = false));
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        this.page = 1;
        obj.page = 1;
        this.params.page = 1;
      }
      obj.search = this.search;
      obj.page = this.page;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "page" && val === 1) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router
        .replace({
          name: "Expenses",
          query: {
            ...obj,
          },
        })
        .catch(() => {});
      this.$store.commit("expenses/SET_EXPENSES_QUERY", obj);
    },
    redirectOnEditPage(event, { item }) {
      this.accessRight.includes("edit")
        ? this.$router.push("expenses/expensesAction/" + item.id)
        : "";
    },
    async deleteItem(item) {
      await this.$store.dispatch("expenses/DeleteExpensesById", item.id);
      this.$store.dispatch("expenses/GetExpenses", {
        page: this.page,
        limit: this.limit,
        where: "account_type|1"
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
</style>